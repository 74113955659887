import React, { useEffect } from 'react';
import { api } from '../../Interceptor'
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap'
import { Button, Card, Container } from 'react-bootstrap';
import GloabalModal from './GlobalModal'
import Userpic from "../../assets/user.png";
import moment from "moment";
import { toastMessage } from 'Helper/Toaster'
import { useDispatch} from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
const CreateCategoryModal = () => {
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
}  
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);

  const deleteBooking = async () => {
    const del = await api.headers().method('post', `/booking/delete/${id}`)
    toastMessage.success("Booking deleted successfully")
    closeModal()
  }
  if (typeOfmodal === "delete-bookings") {
    return (
      <GloabalModal heading="Delete Booking" >
        <h4>Would you like to delete this booking?</h4>
        <Button variant="primary" onClick={deleteBooking}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  return (
    <>
      <GloabalModal heading={typeOfmodal === 'edit-category' ? "View Bookings" : "Create Bookings"}>
        
          <Row>
            <div className='d-flex col-lg-12 col-sm-12 '>
              <img src={data?.talent?.profile_picture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
              <h4 htmlFor="name" className='ml-2 mt-2'> {data?.talent?.first_name} &nbsp; {data?.talent?.last_name} </h4>
  
            </div>

            <div className='col-lg-4 col-sm-12 mt-3'>
              <h4 htmlFor="name">Full Name </h4>

            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="name">{data?.full_name} </label>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Email </h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <a href={`mailto:${data?.email}`} >{data?.email}</a>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Fees </h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="fees">{data?.fees} </label>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="phone_number">Phone Number </h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="phone_number">{data?.phone_number} </label>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="message">Message </h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="message" className='message_bookings'>{data?.message} </label>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="Production">Production </h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="message">{data?.has_production ? 'YES' : 'NO'} </label>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <h4>Created AT</h4>
            </div>
            <div className='col-lg-8 col-sm-12 mt-2'>
              <label htmlFor="date">{moment(data?.createdAt).format("DD-MM-YYYY")} </label>

            </div>
          </Row >
      
      </GloabalModal >
    </>
  )
  
}

export default CreateCategoryModal;
