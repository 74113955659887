const categories = [
  { value: "featured", label: "Featured", is_hidden: true },
  { value: "actor", label: "Actor" },
  { value: "singer", label: "Singer" },
  { value: "model", label: "Model" },
  { value: "dancer", label: "Dancer" },
  { value: "comedian", label: "Comedian" },
  { value: "athlete", label: "Athlete" },
  { value: "influencer", label: "Influencer" },
  { value: "entrepreneur", label: "Entrepreneur" },
];

const interests = [
  { label: "Animals & Pets", value: "Animals & Pets" },
  { label: "Beauty & Hair", value: "Beauty & Hair" },
  { label: "Business & Finance", value: "Business & Finance" },
  { label: "Cars & Motorcycles", value: "Cars & Motorcycles" },
  { label: "Comedy", value: "Comedy" },
  { label: "Children & Parenting", value: "Children & Parenting" },
  { label: "Design", value: "Design" },
  { label: "Education", value: "Education" },
  { label: "Fitness & Health", value: "Fitness & Health" },
  { label: "Fashion", value: "Fashion" },
  { label: "Food & Drinks", value: "Food & Drinks" },
  { label: "Film & TV", value: "Film & TV" },
  { label: "Furniture", value: "Furniture" },
  { label: "Gadgets", value: "Gadgets" },
  { label: "Gaming", value: "Gaming" },
  { label: "Gardening", value: "Gardening" },
  { label: "Music", value: "Music" },
  { label: "Sports", value: "Sports" },
  { label: "Technology", value: "Technology" },
  { label: "Travel", value: "Travel" },
  { label: "Government", value: "Government" },
  { label: "Public Service", value: "Public Service" },
  { label: "Leadership", value: "Leadership" },
  { label: "Culture", value: "Culture" },
];

const ethnicities = [
  { label: "Chinese", value: "chinese" },
  { label: "Malay", value: "malay" },
  { label: "Indian", value: "indian" },
  { label: "Eurasian", value: "eurasian" },
  { label: "Panasian", value: "pan-asian" },
  { label: "Caucasian", value: "caucasian" },
  { label: "Indonesian", value: "indonesian" },
  { label: "Filipino", value: "filipino" },
  { label: "Taiwanese", value: "taiwanese" },
  { label: "Thai", value: "thai" },
  { label: "Korean", value: "korean" },
  { label: "Japanese", value: "japanese" },
  { label: "Vietnamese", value: "vietnamese" },
  { label: "Pakistani", value: "pakistani" },
  { label: "Burmese", value: "burmese" },
];

const locations = [
  {
    label: "Singapore",
    value: "Singapore",
    code: "sg",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
    code: "my",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
    code: "id",
  },
  {
    label: "Philippines",
    value: "Philippines",
    code: "ph",
  },
  {
    label: "India",
    value: "India",
    code: "in",
  },
  {
    label: "China",
    value: "China",
    code: "cn",
  },
  {
    label: "Taiwan",
    value: "Taiwan",
    code: "tw",
  },
  {
    label: "Hong Kong",
    value: "Hong Kong",
    code: "hk",
  },
  {
    label: "Thailand",
    value: "Thailand",
    code: "th",
  },
  {
    label: "South Korea",
    value: "South Korea",
    code: "kr",
  },
  {
    label: "Japan",
    value: "Japan",
    code: "jp",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
    code: "vn",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
    code: "pk",
  },
  {
    label: "Myanmar",
    value: "Myanmar",
    code: "mm",
  },
];

const languages = [
  { label: "English", value: "English" },
  { label: "Mandarin", value: "Mandarin" },
  { label: "Melayu", value: "Melayu" },
  { label: "Tamil", value: "Tamil" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Hindi", value: "Hindi" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Hokkien", value: "Hokkien" },
  { label: "Cantonese", value: "Cantonese" },
  { label: "Thai", value: "Thai" },
  { label: "Korean", value: "Korean" },
  { label: "Japanese", value: "Japanese" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Burmese", value: "Burmese" },
];

export { categories, interests, ethnicities, locations, languages };
