const initial_state = {}

export const modalReducer = (state = initial_state, action) => {
    switch (action.type) {
        case "OPEN_MODAL":
            return {
                ...state,
                isOpen: action.payload.isOpen,
                typeOfmodal: action.payload.typeOfmodal,
                location: action.payload.location || "No location found",
                data: action.payload.data || {},
                id: action.payload.id,
                slug: action.payload.slug,
            }
        case "CLOSE_MODAL":
            return {
                ...state,
                isOpen: action.payload.isOpen,
                id: undefined,
                slug: undefined,
            }

        default:
            return state
    }
}