export const eventtablecolumns = [
    {
        dataField: "row_count",
        text: '#',
        sort: true
    },
    {
        dataField: 'full_name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true
    },

    {
        dataField: 'phone_number',
        text: 'Phone Number',
        sort: true
    },
    {
        dataField: 'profile_picture',
        text: "Profile Picture"
    },
    {
        dataField: 'featured',
        text: 'Featured',
        sort: true
    },
    {
        dataField: 'isActive',
        text: 'Status',
        sort: true
    },
    {
        dataField: 'action',
        text: 'Actions',
    },

]

export const announcementTableColum = [

    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'content_category.title',
        text: 'Category',
    },
    {
        dataField: 'content.markup',
        text: 'content',
    },
    {
        dataField: 'actions',
        text: 'Actions',
    }
]