import { toastMessage } from 'Helper/Toaster'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { api } from '../../Interceptor'
import React, { useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_MODAL } from 'Store/Actions/Modal/Action'
import { CLOSE_MODAL } from 'Store/Actions/Modal/Action'
import DatePicker from "react-datepicker";
import { useFormik, FormikProvider } from "formik";
import GloabalModal from './GlobalModal';
import moment from "moment";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import UploadImage from "../UploadImage/UploadImage";
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Formik, Field } from 'formik';
import {
  categories,
  ethnicities,
  interests,
  languages,
  locations,
} from '../Data/Categories'


const PropertyModal = () => {
  const animatedComponents = makeAnimated();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [images, setImages] = useState([]);
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state)
  const [properties, setTalent] = useState({})
  const [selectDate, setDate] = useState(
    new Date(moment().subtract(13, "years"))
  );
  const [videos, setVideos] = useState([]);
  const navigate = useHistory();
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      birthdate: "",
      email: "",
      height: "",
      ethnicity: "",
      languages: [],
      categories: [],
      interests: [],
      talent_videos: "",
      talent_images: "",
      phone_number: "",
      location: "",
      gender: "",
      platform: "",
      followers_count: "",
      social_media_url: "",
      slug: "",
      profile_index: 0,
      sort_order: null,
    },

    onSubmit: (values) => {

      values.interests = selectedInterests;
      values.languages = selectedLanguages;
      values.categories = selectedCategories;
      values.talent_images = images.filter(x => x.isServerUploaded).map(y => y.url);
      values.talent_videos = videos.filter(x => x).map(
        (x) => `${x}`
      );

      values.social_reach = {
        platform: values.platform,
        followers_count: values.followers_count,
        url: values.social_media_url
      };

      // if (images.length < 2) {
      //   toastMessage.failure("Minimum 2 talent images required");
      //   return;
      // }
      if (values.categories.length === 0) {
        toastMessage.failure("Categories selection is mandatory");
        return;
      }
      if (values.interests.length === 0) {
        toastMessage.failure("Interests selection is mandatory");
        return;
      }
      if (values.languages.length === 0) {
        toast.error("Languages selection is mandatory");
        formik.setSubmitting(false);
        return;
      }

      const requestBody = new FormData();
      images.filter(x => !x.isServerUploaded).map((x) => requestBody.append("talent_images", x.fileData));

      requestBody.append("json_data", JSON.stringify(values));

      if (typeOfmodal === 'update-talent') {
        api.headers().method('put', `/talent/update/${id}`, requestBody).then((response) => {
          toastMessage.success("Talent Updated Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Talent couldn't be submitted!")
        });
      }

      if (typeOfmodal === 'create-talent') {
        api.headers().method("post", `/talent/create`, requestBody).then((response) => {
          toastMessage.success("Talent Created Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Talent couldn't be submitted!")
        });
      }
    },
  });

  const closeModal = () => {
    formik.values.first_name = "";
    formik.values.last_name = "";
    formik.values.email = "";
    formik.values.gender = "";
    formik.values.height = "";
    formik.values.phone_number = "";
    formik.values.followers_count = "";
    formik.values.social_media_url = "";
    formik.values.platform = "";
    formik.values.location = "";
    formik.values.ethnicity = "";
    formik.values.birthdate = "";
    formik.values.languages = "";    
    formik.values.profile_index = 0;    
    formik.values.slug = "";    
    formik.values.sort_order = null;    
    setImages([])
    setVideos([])
    setFiles([])
    dispatch(CLOSE_MODAL({ isOpen: false }));
    setTalent({});
  }

  const deleteTalent = async () => {
    const del = await api.headers().method('post', `/talent/delete/${id}`)
    toastMessage.success("Talent deleted successfully")
    closeModal()
  }

  const featureTalent = async () => {
    await api.headers().method('post', `/talent/feature/${id}`)
    toastMessage.success("Talent featured successfully")
    closeModal()
  }

  const approveTalent = async () => {
    const requestBody = { is_active: !data.is_active }
    const app = await api.headers().method('put', `/talent/approve/${id}`, requestBody)
    toastMessage.success("Talent approved successfully")
    closeModal()
  }

  useEffect(() => {
    if (id) {
      if (typeOfmodal === "update-talent" || typeOfmodal === "view-talent") {
        setTalent({});
        api.headers().method('get', `talent/profile/${slug}`).then((response) => {
          const responseData = response?.data?.talent;

          if (!responseData) return;
          formik.values.first_name = responseData.first_name;
          formik.values.last_name = responseData.last_name;
          formik.values.email = responseData.email;
          formik.values.gender = responseData.gender;
          formik.values.height = responseData.height;
          formik.values.phone_number = responseData.phone_number;
          formik.values.followers_count = responseData.social_reach.followers_count;
          formik.values.social_media_url = responseData.social_reach.url;
          formik.values.platform = responseData.social_reach.platform;
          formik.values.location = responseData.location;
          formik.values.ethnicity = responseData.ethnicity;
          formik.values.birthdate = responseData.birthdate ? new Date(responseData.birthdate) : null;
          formik.values.languages = responseData.languages;
          formik.values.slug = responseData.slug;
          formik.values.sort_order = responseData.sort_order;
          formik.values.profile_index = 0; // responseData.profile_index
          let talent_images = [responseData.profile_picture, ...responseData.talent_images];
          talent_images = talent_images.map(x => ({ url: x, isServerUploaded: true }));
          setImages(talent_images);

          setVideos(responseData.talent_videos);
          setSelectedLanguages(responseData.languages);
          setSelectedCategories(responseData.categories);
          setSelectedInterests(responseData.interests);

        })
      }
    }

    if (typeOfmodal === "create-talent") {
      const emptyProp = {};
      setTalent({ ...emptyProp });
      formik.resetForm();
      setSelectedCategories([]);
      setSelectedInterests([]);
      setSelectedLanguages([]);
      setImages([]);
    }

  }, [id, typeOfmodal])

  if (typeOfmodal === "update-talent" || typeOfmodal === "view-talent" || typeOfmodal === "create-talent") {
    const label = typeOfmodal == "update-talent" ? "Update" : typeOfmodal == "view-talent" ? "View" : "Create";
    return (
      <Formik value={formik}>

        <GloabalModal handleParentClose={closeModal} size="lg" heading={`${label} Talent`} >
          <Form onSubmit={formik.handleSubmit}
            onReset={formik.handleReset} className={`${typeOfmodal == 'view-talent' ? 'prpnx-readonly' : ''}`}>
            <Row>
              <Form.Group className="mb-3 col-lg-3">
                <label>First Name</label>
                <Field className="form-control" type="text" placeholder="First Name" name="first_name" onChange={formik.handleChange}
                  value={formik.values.first_name} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-3">
                <label>Last Name</label>
                <Field className="form-control" type="text" placeholder="Last Name" name="last_name" onChange={formik.handleChange}
                  value={formik.values.last_name} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-3">
                <label>Email</label>
                <Field className="form-control" type="text" placeholder="Email" name="email" onChange={formik.handleChange}
                  value={formik.values.email} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-3">
                <label>Height</label>
                <Field className="form-control" type="text" placeholder="Height" name="height" onChange={formik.handleChange}
                  value={formik.values.height} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-3">
                <label>Mobile Number</label>
                <PhoneInput
                  preferredCountries={locations.map(x => x.code)}
                  value={formik.values.phone_number}
                  autoFormat={true}
                  country="sg"
                  onChange={(phone) => {
                    formik.setFieldValue("phone_number", phone);
                  }}
                  inputClass="intl-input"
                  containerStyle={{
                    width: "100%",
                  }}
                  countryCodeEditable={false}
                  name="phone_number"
                  key="phone_number"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-3">
                <label>Date of Birth</label>
                <DatePicker type="date" placeholder="Date of Birth" name="birthdate" placeholderText="Birth Date"
                  selected={formik?.values?.birthdate}
                  className="form-control"
                  onChange={(date) => {
                    if (moment(date, "DD-MM-YYYY").isValid()) {
                      setDate(date);
                      formik.setFieldValue("birthdate", date);
                    }
                  }}
                  dateFormat="dd-MM-yyyy" />
              </Form.Group>

              <div className='col-lg-3'>
                <label>Ethnicity</label>
                <select class="form-control mb-3" aria-label="Default select example" id="ethnicity"
                  name="ethnicity"
                  onChange={formik.handleChange}
                  value={formik.values.ethnicity}>
                  <option value="">Select Ethnicity</option>
                  {ethnicities.map(eth =>
                    <option value={eth.value}>{eth.label}</option>
                  )}
                </select>
              </div>
              <div className='col-lg-3'>
                <label>Select Gender</label>
                <select class="form-control mb-3 " aria-label="Default select example" name="gender" onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className='col-lg-3'>
                <label>Select Location</label>
                <select class="form-control mb-3 " aria-label="Default select example" name="location" onChange={formik.handleChange}
                  value={formik.values.location}
                >
                  <option value="">Select Location</option>
                  {locations.map(loc =>
                    <option value={loc.value}>{loc.label}</option>
                  )}
                </select>
              </div>

              <div className='col-lg-3'>
                <label>Select Platform</label>
                <select class="form-control mb-3 " aria-label="Default select example" name="platform" onChange={formik.handleChange}
                  value={formik.values.platform}
                >
                  <option value="">Select Platform</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="tiktok">Tiktok</option>
                  <option value="youtube">Youtube</option>
                  <option value="snapchat">Snapchat</option>
                </select>
              </div>
              <Form.Group className="mb-4 col-lg-3">
                <label>Select Followers</label>
                <Field className="form-control" type="text" placeholder="Followers" id="followers_count"
                  name="followers_count"
                  borderWidth={"0.15rem"}
                  onChange={formik.handleChange}
                  value={formik.values.followers_count} />
              </Form.Group>
              <Form.Group className="mb-4 col-lg-3">
                <label>Social Media URL</label>
                <Field className="form-control" type="url" placeholder="Social Media URL" id="social_media_url"
                  name="social_media_url"
                  borderWidth={"0.15rem"}
                  onChange={formik.handleChange}
                  value={formik.values.social_media_url} />
              </Form.Group>
              <Form.Group className="mb-4 col-lg-3">
                <label>Slug</label>
                <Field className="form-control" type="text" placeholder="Slug" id="slug"
                  name="slug"
                  borderWidth={"0.15rem"}
                  onChange={formik.handleChange}
                  value={formik.values.slug} />
              </Form.Group>
              {/* <Form.Group className="mb-4 col-lg-3">
                <label>Sort Order</label>
                <Field className="form-control" type="number" placeholder="Slug" id="sort_order"
                  name="sort_order"
                  borderWidth={"0.15rem"}
                  onChange={formik.handleChange}
                  value={formik.values.sort_order} />
              </Form.Group> */}

              <div className='col-12'></div>

              <Form.Group className="mb-3 col-lg-4">
                <label>Videos</label>
                <Field className="form-control" type="text" placeholder="video 1" name="talent_videos"
                  value={videos[0]}

                  onChange={({ currentTarget }) => {
                    videos[0] = currentTarget.value;
                    setVideos([...videos]);
                  }} />

              </Form.Group>
              <Form.Group className="col-lg-4">
                <label> &nbsp; </label>
                <Field className="form-control" type="text" placeholder="video 2" value={videos[1]}
                  onChange={({ currentTarget }) => {
                    videos[1] = currentTarget.value;
                    setVideos([...videos]);
                  }} />
              </Form.Group>
              <Form.Group className="col-lg-4">
                <label> &nbsp; </label>
                <Field className="form-control" type="text" placeholder="video 3" value={videos[2]}
                  onChange={({ currentTarget }) => {
                    videos[2] = currentTarget.value;
                    setVideos([...videos]);
                  }} />
              </Form.Group>
              <Form.Group className="col-lg-4">
                <label> &nbsp; </label>
                <Field className="form-control" type="text" placeholder="video 4" value={videos[3]}
                  onChange={({ currentTarget }) => {
                    videos[3] = currentTarget.value;
                    setVideos([...videos]);
                  }} />
              </Form.Group>
              <Form.Group className="col-lg-4">
                <label> &nbsp; </label>
                <Field className="form-control" type="text" placeholder="video 5" value={videos[4]}
                  onChange={({ currentTarget }) => {
                    videos[4] = currentTarget.value;
                    setVideos([...videos]);
                  }} />
              </Form.Group>

              <div className='mt-4 col-sm-12'>
                <h2>Add Languages</h2>
                {languages.length > 0 &&
                  languages
                    .map((item, index) => {
                      const slug = (element) => element === item.value;
                      let isSelected = selectedLanguages.findIndex(slug) !== -1;

                      return (
                        <span className='d-inline-block mr-2'>
                          <input class="input" type="checkbox" id={item.value}
                            disabled={selectedLanguages.length >= 4 && !isSelected}
                            checked={selectedLanguages.indexOf(item.value) !== -1}
                            colorScheme="pink"
                            onChange={(e) => {
                              const selected = [...selectedLanguages];
                              if (isSelected) {
                                let index =
                                  selectedLanguages.findIndex(slug);
                                selected.splice(index, 1);
                              } else if (selected.length < 4) {
                                selected.push(item.value);
                              }
                              setSelectedLanguages(selected);
                            }} />
                          <label class="form-check-label ml-2" for={item.value}>
                            {item.label}
                          </label>

                        </span>
                      );
                    })}
              </ div>

              <div className='mt-4 col-sm-12'>
                <h2>Add Categories</h2>
                {categories.length > 0 &&
                  categories
                    .filter((x) => !x.is_hidden)
                    .map((item, index) => {
                      const slug = (element) => element === item.value;
                      let isSelected =
                        selectedCategories.findIndex(slug) !== -1;

                      return (
                        <span className='d-inline-block mr-2'>
                          <input class="input" type="checkbox" id={item.value}
                            disabled={selectedCategories.length >= 3 && !isSelected}
                            checked={selectedCategories.indexOf(item.value) !== -1}
                            colorScheme="pink"
                            onChange={(e) => {
                              const selected = [...selectedCategories];
                              if (isSelected) {
                                let index =
                                  selectedCategories.findIndex(slug);
                                selected.splice(index, 1);
                              } else if (selected.length < 3) {
                                selected.push(item.value);
                              }
                              setSelectedCategories(selected);
                            }} />
                          <label class="form-check-label ml-2" for={item.value}>
                            {item.label}
                          </label>

                        </span>
                      );
                    })}
              </ div>

              <div className='mt-4 col-sm-12'>
                <h2>Add interests</h2>
                {interests.length > 0 &&
                  interests
                    .filter((x) => !x.is_hidden)
                    .map((item, index) => {
                      const slug = (element) => element === item.value;
                      let isSelected =
                        selectedInterests.findIndex(slug) !== -1;

                      return (
                        <span className='d-inline-block mr-2'>
                          <input class="input" type="checkbox" id={item.value}
                            checked={selectedInterests.indexOf(item.value) !== -1}
                            disabled={selectedInterests.length >= 4 && !isSelected}
                            colorScheme="pink"
                            onChange={(e) => {
                              const selected = [...selectedInterests];
                              if (isSelected) {
                                let index =
                                  selectedInterests.findIndex(slug);
                                selected.splice(index, 1);
                              } else if (selected.length < 4) {
                                selected.push(item.value);
                              }
                              setSelectedInterests(selected);
                            }} />
                          <label class="form-check-label ml-2" for={item.value}>
                            {item.label}
                          </label>

                        </span>
                      );
                    })}
              </ div>
              <div className='mb-4 col-lg-12'>

              </div>
              <div className='mb-4 col-lg-12'>
                <UploadImage setProfileIndex={(id) => formik.setFieldValue('profile_index', id)} profileIndex={formik.values.profile_index} images={images} setImages={setImages} />

              </div>
            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              onClick={() => setSubmitted(true)}>
              Submit
            </Button>
          </Form >
        </GloabalModal>
      </Formik>
    )
  }

  if (typeOfmodal === "approve-talent") {
    return (
      <GloabalModal handleParentClose={closeModal} heading={`${!data.is_active ? "Accept" : "Reject"} Talent`} >
        <h4>Would you like to approve this talent?</h4>
        <Button variant="primary" onClick={approveTalent}>{`${!data.is_active ? "Accept" : "Reject"} Talent`}</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }

  if (typeOfmodal === "feature-talent") {
    return (
      <GloabalModal handleParentClose={closeModal} heading={`${!data.categories.includes('featured') ? "Feature" : "Unfeature"} Talent`} >
        <h4>Would you like to approve this talent?</h4>
        <Button variant="primary" onClick={featureTalent}>{`${!data.categories.includes('featured') ? "Feature" : "Unfeature"} Talent`}</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }

  return (
    <GloabalModal handleParentClose={closeModal} heading="Delete Talent" >
      <h4>Would you like to delete this talent?</h4>
      <Button variant="primary" onClick={deleteTalent}>Delete</Button>
      <Button variant="light" onClick={closeModal}>Close</Button>
    </GloabalModal>
  )

}

export default PropertyModal
