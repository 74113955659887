import axios from 'axios';
import { axiosBaseURL } from 'Helper/Constant';
class ApiWrapper {
    constructor() {
        this.axiosInstance = axios.create({
            baseURL: axiosBaseURL,
            //timeout: 4000,
        });
    }

    headers(defaultHeaders = true, headers = []) {
        const token = JSON.parse(localStorage.getItem('superadmin-session'))

        if (defaultHeaders) this.axiosInstance.defaults.headers.common['Authorization'] = `${token?.sessions.token}`;
        else delete this.axiosInstance.defaults.headers.common["Authorization"];
        for (const [key, value] of Object.entries(headers)) {
            this.axiosInstance.defaults.headers.common[key] = value;
        }
        return this;
    }



    async method(type, endPoint, body = null, config = null) {

        if (['post', 'patch', 'put'].includes(type.toLowerCase())) {
            try {
                const response = await this.axiosInstance[type.toLowerCase()](endPoint, body, config);
                return Promise.resolve(response);
            } catch (error) {


                return Promise.reject(error);
            }
        } else if (['get', 'head', 'delete'].includes(type.toLowerCase())) {
            try {
                const response = await this.axiosInstance[type.toLowerCase()](endPoint, config);
                return Promise.resolve(response);
            } catch (error) {

                return Promise.reject(error);
            }
        }

        return Promise.reject(`Invalid method type: ${type.toLowerCase()}`);
    }
}

export const api = new ApiWrapper();