
import {
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { api } from "../../Interceptor";
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from "Helper/Functions";
import BootstrapTable from "react-bootstrap-table-next";
import { eventtablecolumns } from "Helper/TableColumns";
import Header from "components/Headers/Header";
import TalentModal from "components/Modal.js/TalentModal";
import { OPEN_MODAL } from "Store/Actions/Modal/Action";
import { formatNumbers } from "Helper/Functions";
import { Button } from "react-bootstrap";
import Placeholder from "../../assets/user.png";
const Talents = () => {
  const [talents, setTalents] = useState([])
  const [loading, setLoading] = useState(false)
  const [followers, setFollowers] = useState();
  const { modalReducer } = useSelector(state => state)
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state)
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  let x = 0;
  
  const dispatch = useDispatch()
  useEffect(() => {
    getTalents();
  }, [modalReducer]);


  const getTalents = () => {
    setLoading(true)
    api.headers().method('get', '/talents')
      .then(response => {

        response.data.talents.map((talent, id) => {

          talent['row_count'] = id + 1
          eventtablecolumns.forEach(element => {
            if (talent[element.dataField] != null || talent[element.dataField] != undefined) {
              talent[element.dataField] = element.format ? formatNumbers(talent[element.dataField], element.format) : talent[element.dataField];
            } else {
              talent[element.dataField] = "---";
            }
          });
          talent.profile_picture = <img src={talent.profile_picture} onError={({ currentTarget }) => (currentTarget.src = Placeholder)} w="100%"
            class="talent_image"
          />
          talent['phone_number'] = <a href={`tel:${talent.phone_number}`} >{talent.phone_number}</a>

          talent.full_name = `${talent.first_name + " " + talent.last_name}`;
          talent.email = `${talent.email}`;


          x += (talent?.social_reach?.followers_count || 0)
          talent.featured = <span className={`badge ${!talent.categories.includes('featured') ? "badge-danger" : "badge-success"}`}>{!talent.categories.includes('featured') ? "NO" : "YES"}</span>
          talent.isActive = <span className={`badge ${!talent.is_active ? "badge-danger" : "badge-success"}`}>{!talent.is_active ? "Inactive" : "Active"}</span>
          talent.action = <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={(e) => e.preventDefault()}
            >

              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={() => openInNewTab(`https://talentora.co/profile/${talent?.slug}`)}>View Talent</DropdownItem>
              <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: talent?._id, slug: talent?.slug, typeOfmodal: 'update-talent' }))}>Update Talent</DropdownItem>
              <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: talent?._id, data: talent, typeOfmodal: 'approve-talent' }))}>{!talent.is_active ? "Accept Talent" : "Reject Talent"}</DropdownItem>
              <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: talent?._id, data: talent, typeOfmodal: 'feature-talent' }))}>{!talent.categories.includes('featured') ? "Feature Talent" : "Unfeature Talent"}</DropdownItem>
              <DropdownItem className="bg-translucent-danger text-white" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: talent?._id, typeOfmodal: 'delete-talent' }))}>Delete Talent</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          return talent

        })
        console.log(response.data.talents);
        setFollowers(x);
        setTalents(response.data.talents)
        
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)

        showNotification(error);

      })
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div class="row">
          <div class="followers col-lg-6 col-xl-3">
            <div class="card-stats mb-4 mb-xl-0 card">
              <div class="card-body"><div class="row">
                <div class="col"><h5 class="text-uppercase text-muted mb-0 card-title">Followers</h5>
                  <span class="h2 font-weight-bold mb-0">{followers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                <div class="col-auto col">
                  <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i class="fas fa-chart-bar"></i>
                  </div>
                </div>
              </div>

              </div>
            </div>
          </div>
        </div>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">Talents</h3>
                <Button color="primary" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-talent' }))}>Create Talents</Button>
              </CardHeader>
              {loading && <i className="fas fa-spinner fa-pulse"></i>}
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={talents} columns={eventtablecolumns} noDataIndication='No Talents Found' />

            </Card>
          </div>
        </Row>
      </Container>
      <TalentModal />
    </>
  );
};

export default Talents;
