import { chartColors } from "variables/config"
import { litterBags } from "variables/config"
import { toastMessage } from "./Toaster"

export function authChecker() {
  if (localStorage.getItem('superadmin-session')) {
    return true
  }
  return false
}
export function getSupperAdminId() {
  return JSON.parse(localStorage.getItem('superadmin-session'))?.user?._id
}

export const randomValueFromArray = (list) => {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
}

export const getUserInitials = (name, count = 2) => {
  const splittedString = name.split(' ');
  let returnText = "";
  for (let index = 0; index < count; index++) {
    returnText += splittedString[index] ? splittedString[index].charAt(0) : "";
  }
  return returnText;
}

export const showNotification = (errorResponse, message, type = 'success') => {
  try {

    if (errorResponse && errorResponse.response) {
      if (errorResponse.response.data) toastMessage.failure(errorResponse.response.data.message || "Request couldn't be completed");
    } else if (message && type) {
      toastMessage[type](message);
    } else if (errorResponse) {
      toastMessage.failure("Server responded with some error, Try contacting support team.");
    } else {
      toastMessage[type]("Request Completed Successfully");
    }

  } catch (error) {
    toastMessage.failure("Server responded with some error, Try contacting support team.");
  }
}
export const parsePieChartData = (data) => {
  let result = {
    labels: [],
    datasets: [
      {
        label: "Litter KGs Items",
        data: [],
        backgroundColor: [],
        borderWidth: 0,
      },
    ],
  };

  let overallWeightItems = [];
  if (data) {
    data.forEach(element => {
      const weightItems = element.weight_items.map(x => { return { ...x, label: litterBags.find(y => y.field_name == x.weight_type).bagName } })
      overallWeightItems.push(weightItems);
    });
  }

  overallWeightItems = overallWeightItems.flatMap(x => x);

  const weightTypes = [];
  let colorsUsed = 0;
  overallWeightItems.forEach((x) => {
    if (weightTypes.indexOf(x.weight_type) == -1) {
      weightTypes.push(x.weight_type);
      result.labels.push(x.label);
      result.datasets[0].data.push(x.total);
      result.datasets[0].backgroundColor.push(chartColors[colorsUsed] || chartColors[0]);
      colorsUsed += 1;
    } else {
      const weightIndex = weightTypes.indexOf(x.weight_type);
      result.labels[weightIndex] = x.label;
      result.datasets[0].data[weightIndex] += x.total;
    }
  })
  return result;
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const formatNumbers = (number, type) => {
  let defaultOptions = {};

  switch (type) {
    case 'currency':
      defaultOptions = { style: 'currency', currency: 'SGD', currencyDisplay: 'code' };
      break;
  
    default:
      break;
  }
  
  const intlInstance = new Intl.NumberFormat('en-SG', defaultOptions)
  return intlInstance.format(number);
}