import React, { useRef } from "react";
import UploadPlaceholder from "../../assets/img/upload.png";
import Placeholder from "../../assets/img/theme/placeholder-2.jpg";
import Profile from "../../assets/img/user.png";
import { ToastContainer, toast } from 'react-toastify';


const UploadImage = ({ images, setImages, single, profileIndex, setProfileIndex }) => {
  const imageUploadHookRef = useRef();
  const clickToUploadImage = () => {
    imageUploadHookRef.current.click();
  };
  const uploadFiles = ({ target: { files } }) => {
    let filesToShow = Object.values(files);
    if (files.length > 20) {
      filesToShow = filesToShow.slice(0, 20);
      toast.error("Your are not allowed to select more than 20 picture");
    }
    if (images.length > 20) {
      toast.error(
        "Max limit reached. Please remove any previous image to upload new one."
      );
      return false;
    }
    setImages([
      ...images,
      ...filesToShow.map((file) => {
        return { fileData: file, tempURL: URL.createObjectURL(file) };
      }),
    ]);
  };
  const removeImage = (id) => {
    const temporaryImages = [];
    for (let i = 0; i < images.length; i++) {
      if (images[i] !== images[id]) {
        temporaryImages.push(images[i]);
      }
    }
    setImages(temporaryImages);
  };
  console.log(images, "Files");
  return (
    <div className="filepicker-wrapper">
      <span className="filepicker-zone" onClick={clickToUploadImage}>
        <img alt="Zone Icon" src={UploadPlaceholder} />
        <p>
          Drop your images here, or <span className="highlight">Browse</span>
        </p>
        <small>Support JPG, JPEG, PNG, GIF</small>
        <input
          type="file"
          multiple={!single}
          accept="image/*"
          hidden
          ref={imageUploadHookRef}
          onChange={uploadFiles}
        />
      </span>
      {React.Children.toArray(
        images.map((image, id) => {
          return (
            <span className={`filepicker-document ${id === profileIndex ? 'image--profile' : '' }`}>
              <img alt={`Litter ${id + 1}`} onError={({currentTarget}) => currentTarget.src = Placeholder} src={image.isServerUploaded ? image.url : image.tempURL } />
              <button type="button" className="profile-changer" onClick={() => setProfileIndex(id)}>
                <img src={Profile} />
              </button>
              <button type="button" class="btn btn-warning"
                onClick={() => removeImage(id)}
                // className="filepicker-remove"
                pos="absolute"
                top="-10px"
                right="-10px"
                aria-label="Remove"
                size="sm"
              >x</button>
            </span>
          );
        })
      )}
    </div>
  );
};

export default UploadImage;
