import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap'
import GloabalModal from './GlobalModal'
import Userpic from "../../assets/user.png";
import DatePicker from "react-datepicker";
import { useDispatch} from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import moment from "moment";
const ContactModal = () => {
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
 
  const deleteContact = async () => {
    const del = await api.headers().method('post', `/contact/delete/${id}`)
    toastMessage.success("Query deleted successfully")
    closeModal()
  }
  
  const [category, setCategory] = useState({});
  const [selectDate, setDate] = useState(
    new Date(moment().subtract(13, "years"))
  );
  
  const submitCategory = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let body = {}
    for (var pair of formData.entries()) {
      body = {
        ...body,
        [pair[0]]: pair[1]
      }
    }
    if (typeOfmodal === "contact-query") {
      body = {
        ...body,
        content_category_id: category._id
      }
      const { data } = await api.method('post', 'content/category/manage', body)
      toastMessage.success("Category updated")
      return
    }
    const { data } = await api.method('post', 'content/category/manage', body)
    if (data) {
      toastMessage.success("Category created ")
      return
    }
    toastMessage.failure("there is an error in creating catogory please check")
  }

  useEffect(async () => {
    if (typeOfmodal === 'contact-query') {
      const { data: { content_category } } = await api.method('get', `content/category/${id}`)
      setCategory(content_category)
    }
  }, [id]);

  if (typeOfmodal === "delete-queries") {
    return (
      <GloabalModal heading="Delete Queries" >
        <h4>Would you like to delete this Query?</h4>
        <Button variant="primary" onClick={deleteContact}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }

  return (
    <>
      <GloabalModal heading={typeOfmodal === 'contact-query' ? "View Contact" : "Create Contacts"}>
        <form onSubmit={submitCategory}>
          <Row>

            <div className='contact col-lg-4 col-sm-12 mt-3'>
              <h4 htmlFor="name">Full Name </h4>

            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="name">{data?.full_name} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Email </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <a href={`mailto:${data?.email}`} >{data?.email}</a>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="company">Company </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="company">{data?.company} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="phone_number">Phone Number </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="phone_number">{data?.phone} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Fees </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="fees">{data?.fees} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="message">Message </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="message" className="message_bookings">{data?.message} </label>
            </div>
            

            <div className='contact col-lg-4 col-sm-12 mt-2'>
              <h4>Created AT</h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>
              <label htmlFor="date">{moment(data?.createdAt).format("DD-MM-YYYY")} </label>

            </div>
          </Row >
        </form >
      </GloabalModal >

    </>
  )
}

export default ContactModal;
