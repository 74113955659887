
import Login from "views/Pages/Login.js";
import Talents from "./views/Pages/Talents";
import ContactQueries from "views/Pages/ContactQueries";
import Bookings from "views/Pages/Bookings";

var routes = [
  {
    path: "/talents",
    name: "Talents",
    icon: "fas fa-info text-red",
    component: Talents,
    layout: "/admin",
  }, 
  {
    url: "/TalentoraExtendedDatabase.pdf",
    target: "_blank",
    path: "/talents",
    name: "Monthly Active",
    icon: "fas fa-users text-red",
    component: Talents,
    layout: "/admin",
  }, 
  {
    path: "/contact-queries",
    name: "Contact Queries",
    icon: "fa fa-bullhorn text-red",
    component: ContactQueries,
    layout: "/admin",
  },
  {
    path: "/bookings",
    name: "Bookings",
    icon: "fa fa-file-alt text-red",
    component: Bookings,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    hideInSidebar: true,
    layout: "/auth",
  },


];
export default routes;
