import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/ContactModal'
import { toastMessage } from 'Helper/Toaster';

const ContactQueries = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const { modalReducer } = useSelector(state => state)
  async function getData() {
    const { data: { contacts } } = await api.headers().method('get', '/contact/queries')
    contacts.map((cat, id) => {
      
      cat['s.no'] = id + 1
      cat.full_name = `${cat.contact_name || cat.first_name}`
      
      cat['Email'] = <a href={`mailto:${cat.email}`} >{cat.email}</a>
      cat['phone'] = <a href={`tel:${cat.phone_number}`} >{cat.phone_number}</a>
      cat.company = cat.company_name;
      cat.project = cat.project_type;
      
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'contact-query', id: cat._id, data: cat }))}>View Contacts</DropdownItem>
          <DropdownItem className="bg-translucent-danger text-white" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat._id, typeOfmodal: 'delete-queries' }))}>Delete Queries</DropdownItem>
            
        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(contacts)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'full_name',
      text: 'Full Name',
    },
    {
      dataField: 'company',
      text: 'Company Name',
    },
    {
      dataField: 'phone',
      text: 'Phone Number',
    },
    {
      dataField: 'project',
      text: 'Project Type',
    },
    {
      dataField: 'Email',
      text: 'Email',
    },
    {
      dataField: 'actions',
      text: 'Actions',
    },

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">Contact Queries</h3>
                
              </CardHeader>
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns} 
              noDataIndication='No Queries Found'
              />

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ContactQueries;
