import React from 'react'
import { Toast } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const NotificationToaster = () => {
    const { toastReducer } = useSelector(state => state)
    return (
        <div className="toast-notification">
            <Toast show={toastReducer.show} className={`${toastReducer.className || "success"} rounded p-2`}>
                <Toast.Body>
                    <div className="d-flex justify-content-around align-items-center">
                        <i className={`${toastReducer.iconClassName} text-white fa-fade fa-solid`}></i>
                        <p className="text-white mt-3">{toastReducer.message}</p>
                    </div>
                </Toast.Body>
            </Toast>
        </div>
    )
}

export default NotificationToaster
