export const OPEN_MODAL = (payload) => {
    return {
        type: 'OPEN_MODAL',
        payload: payload
    }
}
export const CLOSE_MODAL = (payload) => {
    return {
        type: 'CLOSE_MODAL',
        payload: payload
    }
}