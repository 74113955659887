import PostRequest from "Helper/PostRequest";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

const Login = () => {
  const [error, changeInputValue, LoginAsSuperAdmin, loading] = PostRequest()
  const { toastReducer } = useSelector(state => state)
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={LoginAsSuperAdmin} >
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    onChange={changeInputValue}

                  />
                </InputGroup>
                {error?.email && <strong className="text-danger m-0 p-0 mx-2">{error?.email}</strong>}

              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={changeInputValue}
                    autoComplete="new-password"
                  />
                </InputGroup>
                {error?.password && <strong className="text-danger m-0 p-0 mx-2">{error?.password}</strong>}

              </FormGroup>

              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={toastReducer.isDisabled}>
                  {loading ? <i class="fas fa-spinner fa-pulse"></i> : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
